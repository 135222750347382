import ReactJson from "react-json-view";
import React, { useEffect, useState } from "react";
import { Props } from "../types";
import { Box, useTheme, useMediaQuery } from "@mui/material";

const Json = (props: Props) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const isMobile = useMediaQuery("(max-width:668px)");
  const [data, setData] = useState<object>();

  useEffect(() => {
    const xhr = createRequest(props.src);
    try {
      xhr.send();
    } catch (e) {
      console.error("Could not render JSON file");
    }
  }, []);

  const createRequest = (path: string) => {
    let xhr = new XMLHttpRequest();

    if ("withCredentials" in xhr) {
      // XHR for Chrome/Firefox/Opera/Safari.
      xhr.open("GET", path, true);
    } else {
      // CORS not supported.
      xhr = null;
      return null;
    }

    xhr.onload = () => {
      if (xhr.status >= 400) {
        console.error("Could not render CSV file");
        return;
      }
      //const resp = props.responseType ? xhr.response : xhr.responseText;

      setData(JSON.parse(xhr.responseText));
    };

    return xhr;
  };

  if (!data) {
    return null;
  }

  return (
    <Box
      className={props.className}
      sx={{
        height: `${props.height - 72}px`,
        overflow: "auto",
        marginLeft: isMobile ? 4 : "auto",
        marginRight: isMobile ? 4 : "auto",
        marginTop: isMobile ? 5 : 10,
        width: `${props.width}px`,
        textAlign: "left"
      }}
    >
      <span className="swiper-no-swiping">
        <ReactJson
          src={data}
          theme={isDarkMode ? "summerfruit" : "summerfruit:inverted"}
          style={{
            backgroundColor: "transparent",
            fontFamily:
              "'SFMono-Regular',Consolas,'Liberation Mono', Menlo, Courier,monospace",
            fontSize: "14px"
          }}
        />
      </span>
    </Box>
  );
};

export default Json;
