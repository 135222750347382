import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Props } from "../types";
import MDEditor from "@uiw/react-md-editor";
import "../../../theme/markdown-colors.css";

const Note = (props: Props) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const isMobile = useMediaQuery("(max-width:668px)");

  return (
    <Box
      sx={{
        height: `${props.height - 72}px`,
        overflow: "auto",
        marginLeft: isMobile ? 4 : "auto",
        marginRight: isMobile ? 4 : "auto",
        marginTop: isMobile ? 5 : 10,
        width: `${props.width}px`,
        textAlign: "left"
      }}
    >
      <span className="swiper-no-swiping">
        <div data-color-mode={isDarkMode ? "dark" : "light"} className="md-gallery">
          <MDEditor preview="preview" value={props.src} height={window.innerHeight - 250} hideToolbar />
        </div>
      </span>
    </Box>
  );
};

export default Note;
