import React from "react";
import {
  CloseIcon,
  DarkModeGalleryIcon,
  DoubleChevronLeftIcon,
  DownloadIcon,
  GroupTileIcon,
  ImageIcon,
  LightModeGalleryIcon,
  PrinterIcon,
  ViewTransactionIcon,
  VaultIcon
} from "@akord/addon-icons";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { FileInput, GalleryProps } from "./types";
import { FileType } from "../FileViewer/File/types";
import FileVersionSelect from "../FileViewer/FileVersionSelect/FileVersionSelect";
import LabelSpinner from "../Loader/LabelSpinner/LabelSpinner";

interface TopMenuBarProps extends GalleryProps {
  activeFileGroup: Array<FileInput>;
  isMobile: boolean;
  isTablet: boolean;
  mode: "single" | "group";
  loadErrorRef: React.MutableRefObject<boolean>;
  darkMode: boolean;
  groupedFiles: Map<String, FileInput[]>;
  onRevisionByHash: (hash: string) => void;
  onMode: (value: React.SetStateAction<"single" | "group">) => void;
  onClose: () => void;
  onModalOpen: () => void;
  onDarkMode: (value: React.SetStateAction<Boolean>) => void;
  onDownload: () => void;
}

export const TopMenuBar = (props: TopMenuBarProps) => {
  const {
    isMobile,
    isTablet,
    mode,
    activeFileGroup,
    onRevisionByHash,
    loadErrorRef,
    darkMode,
    groupedFiles,
    onMode,
    onClose,
    onModalOpen,
    onDarkMode,
    onDownload
  } = props;

  const renderLocation = () => {
    if (props.navigationComponent) {
      return props.navigationComponent;
    }
    if (props.navigation) {
      const folders = props.navigation.split(",");
      if (folders.length > 1) {
        const root = folders.shift();
        return (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <IconButton>
              <DoubleChevronLeftIcon sx={{ fontSize: "24px" }}></DoubleChevronLeftIcon>
            </IconButton>
            <Typography sx={{ marginLeft: 2, marginTop: 1, color: "text.secondary" }}>{root} / </Typography>
            {folders.map((folder, idx) => (
              <Typography key={idx} variant="h3" sx={{ marginLeft: 2, marginTop: 1 }}>
                {folder} {idx !== folders.length - 1 ? "/" : ""}{" "}
              </Typography>
            ))}
          </Box>
        );
      }
      return (
        <Box fontWeight="bold" display="flex" justifyContent="space-between" alignItems="center">
          <VaultIcon color="secondary" sx={{ fontSize: "24px" }}></VaultIcon>
          <Typography variant="h3" sx={{ marginLeft: 2, marginTop: 1 }}>
            {props.navigation}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        minHeight: "31px",
        margin: 4,
        marginBottom: isMobile ? 2 : isTablet ? 0 : 7
      }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      {renderLocation()}
      {props.groupFilesEnabled && mode === "single" && !isTablet && activeFileGroup && (
        <Box width="25%">
          <FileVersionSelect files={activeFileGroup} onSelect={onRevisionByHash}></FileVersionSelect>
        </Box>
      )}
      {isMobile && (
        <IconButton size="large" onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      )}
      {!isMobile && (
        <Box display="flex">
          {(!activeFileGroup || !activeFileGroup[0].name) && groupedFiles?.size > 0 && !isMobile && props.loaderEnabled && (
            <LabelSpinner label={props.loaderText} sx={{ marginRight: 4 }} />
          )}
          {props.publicShareEnabled && (
            <Button variant="contained" size="xs" color="primary" sx={{ marginRight: 4 }} onClick={() => onModalOpen()}>
              Share
            </Button>
          )}
          {props.modeSwitchEnabled && !isMobile && mode === "group" && (
            <Tooltip title="Single" arrow>
              <IconButton size="large" sx={{ marginRight: 3 }} onClick={() => onMode("single")}>
                <ImageIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
          {props.modeSwitchEnabled && !isMobile && mode === "single" && (
            <Tooltip title="Gallery" arrow>
              <IconButton size="large" sx={{ marginRight: 3 }} onClick={() => onMode("group")}>
                <GroupTileIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
          {props.themeSwitchEnabled && (
            <Tooltip title={darkMode ? "Light mode" : "Dark mode"} arrow>
              <IconButton
                size="large"
                sx={{ marginRight: 3 }}
                onClick={() => {
                  onDarkMode(!darkMode);
                  props.onThemeSwitch && props.onThemeSwitch();
                }}
              >
                {darkMode ? <LightModeGalleryIcon /> : <DarkModeGalleryIcon />}
              </IconButton>
            </Tooltip>
          )}
          {props.viewTransactionEnabled && activeFileGroup && activeFileGroup[0].txId && (
            <Tooltip title="View transaction" arrow>
              <IconButton
                size="large"
                sx={{ marginRight: 3 }}
                onClick={() => window.open(`${props.transactionBaseUrl}/${activeFileGroup[0].txId}`, "_blank")}
              >
                <ViewTransactionIcon />
              </IconButton>
            </Tooltip>
          )}
          {props.viewTransactionEnabled && activeFileGroup && !activeFileGroup[0].txId && (
            <Tooltip title="Posting transaction in progress..." arrow>
              <span style={{ display: "flex" }}>
                <IconButton size="large" sx={{ marginRight: 3 }} disabled>
                  <ViewTransactionIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {props.printEnabled && activeFileGroup && (
            <Tooltip title="Print" arrow>
              <span style={{ display: "flex", marginRight: "12px" }}>
                <IconButton
                  size="large"
                  onClick={() => print()}
                  disabled={
                    activeFileGroup[0].type === FileType.note ||
                    activeFileGroup[0].type === FileType.mp3 ||
                    activeFileGroup[0].type === FileType.mp4 ||
                    loadErrorRef.current
                  }
                >
                  <PrinterIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {props.downloadEnabled && activeFileGroup && (
            <Tooltip title="Download" arrow>
              <IconButton size="large" sx={{ marginRight: 3 }} onClick={() => onDownload()}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Close" arrow>
            <IconButton size="large" onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};
