
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CSV from 'comma-separated-values';
import React, { useEffect, useState } from 'react';
import { Props } from '../types';

const Csv = (props: Props) => {

  const [data, setData] = useState<string>()

  useEffect(() => {
    const xhr = createRequest(props.src)
    try {
      xhr.send();
    } catch (e) {
      console.error("Could not render CSV file")
    }

  }, [])

  const createRequest = (path: string) => {
    let xhr = new XMLHttpRequest();

    if ('withCredentials' in xhr) {
      // XHR for Chrome/Firefox/Opera/Safari.
      xhr.open('GET', path, true);
    } else {
      // CORS not supported.
      xhr = null;
      return null;
    }

    xhr.onload = () => {
      if (xhr.status >= 400) {
        console.error("Could not render CSV file")
        return;
      }
      //const resp = props.responseType ? xhr.response : xhr.responseText;

      setData(xhr.responseText)
    };

    return xhr;
  }


  const parse = (data) => {
    const rows = [];
    const columns = [];

    new CSV(data).forEach((array) => {
      rows.push(array);
    });

    return { rows, columns };
  }

  if (!data) {
    return null
  }

  const { rows, columns } = parse(data)
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {row.map((cell, cellIdx) => (
                <TableCell key={cellIdx} component="th" scope="row">
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Csv
