import { Box, CardMedia } from "@mui/material";
import React from "react";
import { Props } from "../types";

const Image = (props: Props) => {
  return (
    <Box className={props.className} display="flex" justifyContent="center" alignItems="center" minHeight={props.height}>
      <CardMedia
        component="img"
        image={props.src}
        height={props.fixedHeight ? props.height : "auto"}
        sx={{
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          maxHeight: props.fixedHeight ? "auto" : props.height,
          objectFit: "contain"
        }}
      ></CardMedia>
    </Box>
  );
};

export default Image;
