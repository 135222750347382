import { PasswordMaskIcon } from "@akord/addon-icons";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";
import { grey } from "../../../theme/colors";

export type Props = {
  src: string;
  name: string;
  overSized: boolean;
  width: number | "auto";
  height: number;
  mode: "single" | "group";
  previewLimitSize: number;
  onDownload: () => void;
};

const FileViewerError = (props: Props) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const isMobile = useMediaQuery("(max-width:668px)");

  const download = async () => {
    const link = document.createElement("a");
    link.href = props.src;
    link.download = props.name;
    link.click();
  };

  const renderBody = () => (
    <Box>
      <Box marginBottom="8px">
        <PasswordMaskIcon color="disabled" />
      </Box>
      <Typography variant="h2" style={{ marginBottom: "8px" }}>
        No preview available
      </Typography>
      <Typography variant="body2" style={{ marginBottom: "8px" }}>
        {props.overSized
          ? `Unfortunately, we’re unable to preview files over ${
              props.previewLimitSize / 1e6
            } MB due to restrictions when decrypting files in the browser. To view the file, you can download it.`
          : "Unfortunately, we’re unable to preview this particular file format. To view the file, you can download it."}
      </Typography>
      <Typography variant="body2" style={{ marginBottom: "20px" }}>
        If the file is sensitive, please do not keep a copy on your device for
        longer than necessary.
      </Typography>
      <Button
        style={{ minWidth: "auto" }}
        variant="contained"
        color="primary"
        disableElevation
        type="button"
        onClick={() => props.onDownload()}
      >
        Download
      </Button>
    </Box>
  );

  if (props.mode === "single") {
    return (
      <Box
        overflow={"auto"}
        width={isMobile ? `${window.innerWidth - 32}px` : props.width}
        height={props.height}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          borderRadius="8px"
          px={7}
          pt={3}
          pb={6}
          sx={{ bgcolor: isDarkMode ? grey[800] : grey[100] }}
        >
          {renderBody()}
        </Box>
      </Box>
    );
  }
  return (
    <Box
      margin="auto"
      textAlign="center"
      position="relative"
      width={isMobile ? `${window.innerWidth - 32}px` : props.width}
      height={props.height}
      sx={{ bgcolor: isDarkMode ? grey[800] : grey[100] }}
    >
      <Box
        margin={0}
        position="absolute"
        top="50%"
        style={{
          transform: "translateY(-50%)"
        }}
        boxSizing="border-box"
        px={7}
        pt={3}
        pb={6}
      >
        {renderBody()}
      </Box>
    </Box>
  );
};

export default FileViewerError;
