import { MenuDotsHorizontalIcon } from "@akord/addon-icons";
import { Box, Fab, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { HashNavigation, Keyboard, Navigation, Pagination, Zoom } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperClass from "swiper/types/swiper-class";
import File from "../FileViewer/File/File";
import { FileType } from "../FileViewer/File/types";
import FileVersionSelect from "../FileViewer/FileVersionSelect/FileVersionSelect";
import DialogWithForm from "../Dialogs/DialogWithForm";
import "./styles.css";
import { FileInput, GalleryProps, Handle } from "./types";
import { shareDialoglViewProps, shareDialogViewOnSubmit } from "../Dialogs/DialogWithForm/share-form-modal-options";
import { shareSnackbarViewProps } from "../SnackbarNotification/share-snackbar-options";
import SnackbarNotification from "../SnackbarNotification";
import FileViewerNoFiles from "../FileViewer/FileViewerNoFiles";
import { MobileMenuDrawer } from "./MobileMenuDrawer";
import { NavigatonArrows } from "./NavigatonArrows";
import { TopMenuBar } from "./TopMenuBar";

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

SwiperCore.use([HashNavigation, Keyboard, Navigation, Pagination, Zoom]);

const BOTTOM_NAVIGATION_VERTICAL_SIZE = 158;
const BOTTOM_NAVIGATION_VERTICAL_SIZE_MOBILE = 200;

const Gallery = (props: GalleryProps) => {
  const isMobile = useMediaQuery("(max-width:668px)");
  const isTablet = useMediaQuery("(max-width:1100px)");

  const [files, setFiles] = useState<Array<FileInput>>();
  const [groupedFiles, setGroupedFiles] = useState<Map<String, Array<FileInput>>>();
  const [activeFileGroup, setActiveFileGroup] = useState<Array<FileInput>>();
  const [activeFile, setActiveFile] = useState<Handle<typeof File>>();
  const [activeFileIndex, setActiveFileIndex] = useState<number>(0);
  const [swiperRerenders, setSwiperRerenders] = useState<number>(0);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [darkMode, setDarkMode] = useState<Boolean>(true);
  const [mode, setMode] = useState<"single" | "group">(isTablet ? "single" : props.mode || "group");
  const [showPagination, setShowPagination] = useState(true);
  const [isZoomed, setIsZoomed] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // Ref to have access to a render error in File
  const loadErrorRef = useRef(false);
  const filesRef = useRef<Array<Handle<typeof File>>>([]);

  useEffect(() => {
    loadFiles();
    initDimenistions();
  }, [props.files]);

  useEffect(() => {
    if (swiper) {
      swiper.update();
    }
    window.addEventListener("resize", () => {
      handleResize(updateSwiper);
    });
    return () => window.removeEventListener("resize", updateSwiper);
  }, [swiper]);

  const handleResize = debounce(() => {
    if (!document.fullscreenElement) {
      initDimenistions();
      updateSwiper();
    }
  }, 200);

  useEffect(() => {
    if (!activeFile && filesRef && filesRef.current) {
      setActiveFile(filesRef.current[0]);
    }
  }, [activeFile, filesRef.current]);

  useEffect(() => {
    if (mode === "single") {
      setShowPagination(false);
    } else if (mode === "group") {
      setShowPagination(true);
    }
    updateSwiper();
  }, [mode]);

  useEffect(() => {
    filesRef.current = filesRef.current.slice(0, files?.length);
  }, [files]);

  useEffect(() => {
    if (groupedFiles) {
      setActiveFileGroup(Array.from(groupedFiles.values())[activeFileIndex]);
    }
  }, [activeFileIndex, groupedFiles]);

  useEffect(() => {
    if (isTablet) {
      setMode("single");
      initDimenistions();
    }
  }, [isTablet]);

  const loadFiles = React.useCallback(() => {
    // Adding check for activeFileGroup to prevent reload on mode switch
    if (!props.files || activeFileGroup?.length > 0) return;
    const groupedFiles = new Map();
    props.files?.forEach(file => {
      const key = file.group || file.hash;
      if (!groupedFiles.has(key)) {
        groupedFiles.set(key, [file]);
      } else {
        groupedFiles.get(key).push(file);
      }
    });
    Array.from(groupedFiles.keys()).forEach(key => groupedFiles.get(key).sort((a, b) => b.version - a.version));

    const firstInGroupFiles = Array.from(groupedFiles.values()).map(group => group[0]);
    if (window.location.hash) {
      const urlActiveFileIndex = firstInGroupFiles.findIndex(file => window.location.hash.includes(file.hash));
      if (urlActiveFileIndex > 0) {
        setActiveFileIndex(urlActiveFileIndex);
      }
    }
    setFiles(firstInGroupFiles);
    setGroupedFiles(groupedFiles);
  }, [props.files]);

  const initDimenistions = () => {
    const { height, width } = getDimensions();
    setWidth(width);
    setHeight(height);
  };

  const updateSwiper = () => {
    setSwiperRerenders(swiperRerenders + 1);
    updateSwiperSlides();
  };

  const updateSwiperSlides = () => {
    if (swiper) {
      setTimeout(() => swiper.update(), 300);
    }
  };

  const getHeight = (): number => {
    if (props.height === "auto") {
      if (isTablet) {
        return window.innerHeight - BOTTOM_NAVIGATION_VERTICAL_SIZE_MOBILE;
      }
      return window.innerHeight - BOTTOM_NAVIGATION_VERTICAL_SIZE;
    }
    return props.height;
  };

  const getDimensions = () => {
    const height = getHeight();
    const width = Math.min((height * 3) / 4, window.innerWidth);
    return { height, width };
  };

  const hasRevisions = () => {
    return props.groupFilesEnabled;
  };

  const slideChangedHandler = (activeSlideIndex: number) => {
    setActiveFileIndex(activeSlideIndex);
    setActiveFile(filesRef.current[activeSlideIndex]);
    setActiveFileGroup(Array.from(groupedFiles.values())[activeSlideIndex]);
    setRevisionByIndex(0);
  };

  const setRevisionByHash = (hash: string) => {
    if (hasRevisions()) {
      const index = activeFileGroup.findIndex(file => file.hash === hash);
      if (index) {
        const fileGroup = [activeFileGroup[index], ...activeFileGroup.slice(0, index), ...activeFileGroup.slice(index + 1)];
        setActiveFileGroup(fileGroup);
        const groups = new Map(groupedFiles);
        groups.set(activeFileGroup[index].group, fileGroup);
        setGroupedFiles(groups);
        setFiles([...files.slice(0, activeFileIndex), activeFileGroup[index], ...files.slice(activeFileIndex + 1)]);
        updateSwiper();
      }
    }
  };

  const setRevisionByIndex = (index: number) => {
    if (hasRevisions() && activeFileGroup) {
      const revision = activeFileGroup[index];
      setFiles([...files.slice(0, activeFileIndex), revision, ...files.slice(activeFileIndex + 1)]);
    }
  };

  const onClose = () => {
    if (swiper) {
      swiper.destroy();
    }
    if (props.onClose) {
      props.onClose();
    } else if (props.closeUrl) {
      window.location.href = props.closeUrl;
    } else {
      history.back();
    }
  };

  const print = () => {
    if (activeFile) {
      filesRef.current[activeFileIndex].print();
    }
  };

  const download = () => {
    if (activeFile) {
      filesRef.current[activeFileIndex].download();
      // files[activeFileIndex].onDownload();
    }
  };

  const onFileLoadSuccess = React.useCallback(
    (hash: string, fileName: string, type: FileType, src: string) => {
      const index = files.findIndex(file => file.hash === hash);
      const file = files[index];
      file.name = fileName;
      file.type = type;
      file.src = src;
      file.onLoad = null;
      file.toLoad = null;
      setFiles([...files.slice(0, index), file, ...files.slice(index + 1)]);
      if (activeFile?.hash === hash) {
        const currentFile = { ...activeFile };
        currentFile.fileName = fileName;
        currentFile.type = type;
        currentFile.isLoading = false;
        setActiveFile(currentFile);

        const activeGroupIndex = activeFileGroup.findIndex(file => file.hash === hash);
        if (activeGroupIndex < 0) return;

        activeFileGroup[activeGroupIndex].name = fileName;
        activeFileGroup[activeGroupIndex].type = type;
        activeFileGroup[activeGroupIndex].src = src;
        setActiveFileGroup([...activeFileGroup]);

        groupedFiles.set(file.group, activeFileGroup);
        setGroupedFiles(groupedFiles);
      }
      updateSwiperSlides();
    },
    [files]
  );

  const renderFilesSlides = () => {
    if (!files) return null;
    if (files.length === 0) {
      return (
        <SwiperSlide>
          <FileViewerNoFiles mode={props.mode} width={width} height={height} onClose={onClose} />
        </SwiperSlide>
      );
    }
    return files.map((file, idx) => {
      return (
        <SwiperSlide key={idx} data-hash={file.hash}>
          <File
            fileName={file.name}
            hash={file.hash}
            src={file.src}
            type={file.type}
            size={file.size}
            toLoad={file.toLoad}
            onLoad={file.onLoad}
            mode={mode}
            width={width}
            height={height}
            className="swiper-zoom-container"
            ref={el => (filesRef.current[idx] = el)}
            onDownload={file.onDownload}
            onLoadSuccess={onFileLoadSuccess}
            previewLimitSize={props.previewLimitSize}
            loadErrorRef={loadErrorRef}
          />
        </SwiperSlide>
      );
    });
  };

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(!snackbarOpen);
  };

  let shareBaseUrl = props.publicShareUrl ?? `${props.publicShareBaseUrl}/vaults/active/${props.vaultId}/gallery`;
  const shareLink = files
    ? props.folderId
      ? `${shareBaseUrl}/folders/${props.folderId}#${files[activeFileIndex]?.hash}`
      : `${shareBaseUrl}#${files[activeFileIndex]?.hash}`
    : null;

  return (
    <Box className="host">
      <TopMenuBar
        isMobile={isMobile}
        isTablet={isTablet}
        mode={mode}
        activeFileGroup={activeFileGroup}
        loadErrorRef={loadErrorRef}
        darkMode={darkMode}
        groupedFiles={groupedFiles}
        onRevisionByHash={setRevisionByHash}
        onMode={setMode}
        onClose={onClose}
        onModalOpen={handleModalOpen}
        onDarkMode={setDarkMode}
        onDownload={download}
        {...props}
      />
      {isTablet && activeFileGroup && (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            paddingLeft: 2,
            paddingRight: 2,
            minHeight: "56px",
            justifyContent: "space-evenly",
            marginBottom: isMobile ? 3 : 6
          }}
        >
          <FileVersionSelect files={activeFileGroup} onSelect={setRevisionByHash}></FileVersionSelect>
        </Box>
      )}

      <Box className={[isZoomed && "zoomed", isDragging && "dragging"].filter(e => !!e).join(" ")} mx={4}>
        <Swiper
          key={swiperRerenders}
          onSwiper={swiperInstance => {
            setSwiper(swiperInstance);
          }}
          onSlideChange={swiperInstance => slideChangedHandler(swiperInstance.activeIndex)}
          onTap={(_s, e) => {
            const targetElementType = (e.target as HTMLElement).localName;
            if (
              swiper &&
              targetElementType !== "button" &&
              targetElementType !== "anchor" &&
              targetElementType !== "svg" &&
              targetElementType !== "div"
            ) {
              swiper.emit("doubleTap", e);
            }
          }}
          onTouchMove={(_s, e) => {
            setIsDragging(true);
          }}
          onTransitionEnd={() => {
            setIsDragging(false);
            if (activeFileIndex === files.length - 1) {
              if (props.onLast && activeFileIndex > 0) {
                props.onLast();
              }
            } else if (activeFileIndex === 0) {
              props.onFirst && props.onFirst();
            }
          }}
          onZoomChange={() => setIsZoomed(!isZoomed)}
          initialSlide={activeFileIndex}
          keyboard={{
            enabled: true,
            onlyInViewport: false
          }}
          hashNavigation={{
            replaceState: true,
            watchState: true
          }}
          preloadImages={false}
          lazy={true}
          zoom={true}
          loop={false}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={mode === "single" ? 1 : 3}
          spaceBetween={80}
          speed={1000}
          threshold={5}
          watchOverflow={false}
          pagination={{
            el: ".swiper-pagination",
            type: "fraction",
            clickable: true,
            formatFractionTotal: total => {
              if (props.totalFilesCount) {
                return props.totalFilesCount;
              }
              return total;
            },
            formatFractionCurrent: current => {
              if (props.startPosition) {
                return current + props.startPosition;
              }
              return current;
            }
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          }}
        >
          {renderFilesSlides()}
          {mode === "group" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 2
              }}
            >
              <Typography
                variant="body2"
                color="textPrimary"
                sx={{
                  marginTop: 5,
                  marginBottom: 2
                }}
              >
                {activeFile?.fileName}
              </Typography>
            </Box>
          )}
          {files && files.length > 0 && (
            <Box mt={2} style={{ display: "flex", justifyContent: "center", position: "initial" }}>
              {showPagination && <Box sx={{ color: "background.switch", typography: "caption" }} className="swiper-pagination" />}
              <NavigatonArrows showPagination={showPagination} arweaveLogoEnabled={props.arweaveLogoEnabled} />
            </Box>
          )}
        </Swiper>
      </Box>
      {isMobile && (
        <>
          <Fab
            size="medium"
            color="primary"
            sx={{
              position: "fixed",
              right: "16px",
              bottom: "16px"
            }}
            onClick={() => {
              setIsDrawerOpened(true);
            }}
          >
            <MenuDotsHorizontalIcon sx={{ fontSize: "30px" }} />
          </Fab>
          <MobileMenuDrawer
            darkMode={darkMode}
            onDarkMode={setDarkMode}
            isDrawerOpened={isDrawerOpened}
            activeFile={activeFile}
            activeFileGroup={activeFileGroup}
            publicShareEnabled={props.publicShareEnabled}
            handleDrawerOpen={() => setIsDrawerOpened(!isDrawerOpened)}
            handleDownload={() => download()}
            transactionBaseUrl={props.transactionBaseUrl}
            onThemeSwitch={props.onThemeSwitch}
            handleModal={() => handleModalOpen()}
            onPrint={print}
          />
        </>
      )}
      {props.publicShareEnabled && files && (
        <DialogWithForm
          dialogViewProps={props.isCloudVault ? shareDialoglViewProps.cloud : shareDialoglViewProps.permanent}
          dialogViewOnSubmit={() => {
            shareDialogViewOnSubmit(shareLink);
            handleModalOpen();
            handleSnackbarOpen();
          }}
          dialogOpen={modalOpen}
          onDialogClose={() => handleModalOpen()}
          dialogData={{
            ...files[activeFileIndex],
            value: shareLink
          }}
        />
      )}
      <SnackbarNotification snackbarShow={snackbarOpen} snackbarViewProps={shareSnackbarViewProps} onSnackbarClose={handleSnackbarOpen} />
    </Box>
  );
};

export default Gallery;
