import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Props } from "../types";

const PlainText = (props: Props) => {
  const isMobile = useMediaQuery("(max-width:668px)");
  const [data, setData] = useState<string>();

  useEffect(() => {
    const xhr = createRequest(props.src);
    try {
      xhr.send();
    } catch (e) {
      console.error("Could not render MD file");
    }
  }, []);

  const createRequest = (path: string) => {
    let xhr = new XMLHttpRequest();

    if ("withCredentials" in xhr) {
      // XHR for Chrome/Firefox/Opera/Safari.
      xhr.open("GET", path, true);
    } else {
      // CORS not supported.
      xhr = null;
      return null;
    }

    xhr.onload = () => {
      if (xhr.status >= 400) {
        console.error("Could not render TXT file");
        return;
      }
      //const resp = props.responseType ? xhr.response : xhr.responseText;

      setData(xhr.responseText);
    };

    return xhr;
  };

  return (
    <Box
      sx={{
        height: isMobile ? `${props.height - 52}px` : `${props.height - 72}px`,
        overflow: "auto",
        marginLeft: isMobile ? 4 : "auto",
        marginRight: isMobile ? 4 : "auto",
        marginTop: isMobile ? 0 : 10,
        width: isMobile ? "auto" : `${props.width}px`,
        textAlign: "left"
      }}
    >
      <span className="swiper-no-swiping">
        <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
          {data}
        </Typography>
      </span>
    </Box>
  );
};

export default PlainText;
