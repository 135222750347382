import { ImageIcon } from "@akord/addon-icons";
import { Box, useMediaQuery } from "@mui/material";
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useInViewport } from "react-in-viewport";
import FileViewerError from "../FileViewerError";
import Image from "../Image";
import Note from "../Note";
import Pdf from "../Pdf";
import Csv from "../Csv";
import Media from "../Media";
import Doc from "../Doc";
import Json from "../Json";
import Md from "../Md";
import { FileProps, FileType } from "./types";
import PlainText from "../PlainText";
import printJS from "print-js-updated";
import mammoth from "mammoth";

const File = forwardRef(
  (
    props: FileProps,
    ref: Ref<{
      fileName: string;
      hash: string;
      type: FileType;
      isLoading: boolean;
      print: () => void;
      download: () => void;
    }>
  ) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [loadedSrc, setLoadedSrc] = useState<string>();
    const [loadedFileName, setLoadedFileName] = useState<string>();
    const [loadedType, setLoadedType] = useState<FileType>();
    const loaderRef = useRef();
    const isMobile = useMediaQuery("(max-width:668px)");
    const { inViewport } = useInViewport(loaderRef, {}, { disconnectOnLeave: false }, props);

    useEffect(() => {
      if (!loadedFileName || !loadedSrc || !loadedType) {
        init();
      }
    }, [inViewport]);

    useEffect(() => {
      if (props.onLoad && loadedFileName && loadedType && loadedSrc) {
        props.onLoadSuccess(props.hash, loadedFileName, loadedType, loadedSrc);
      }
    }, [loadedFileName, loadedType, loadedSrc]);

    useImperativeHandle(ref, () => ({
      fileName: loadedFileName,
      type: loadedType,
      hash: props.hash,
      isLoading: isLoading,
      print,
      download
    }));

    const getResourceUri = (uris, resource) => {
      const uri = uris.find(uri => uri.startsWith(`${resource}:`));
      return uri.replace(`${resource}:`, "");
    };

    const init = async () => {
      if (props.size > props.previewLimitSize) {
        const { name, type, resourceUri } = props.toLoad;
        setLoadedFileName(name);
        // If file is bigger then the limit set up resourceUrl as a name
        setLoadedSrc(getResourceUri(resourceUri, "s3"));
        setLoadedType(type ? type : name.split(".").pop());
        props.loadErrorRef.current = true;
        setIsError(true);
      } else {
        if (props.onLoad && props.toLoad) {
          if (inViewport) {
            const { fileName, src, type } = await props.onLoad(props.toLoad);
            setLoadedFileName(fileName);
            setLoadedSrc(src);
            setLoadedType(type);
          }
        } else if (props.src instanceof Promise) {
          props.src
            .then(src => {
              setLoadedFileName(props.fileName);
              setLoadedType(props.type);
              setLoadedSrc(src);
            })
            .catch(e => {
              console.error(e);
              props.loadErrorRef.current = true;
              setIsError(true);
            })
            .finally(() => setIsLoading(false));
        } else {
          if (props.fetch) {
            const res = await fetch(props.src);
            const buffer = await res.arrayBuffer();
            setLoadedSrc(URL.createObjectURL(new Blob([buffer])));
          } else {
            setLoadedFileName(props.fileName);
            setLoadedType(props.type);
            setLoadedSrc(props.src);
          }
        }
      }
      setIsLoading(false);
    };

    const getDriver = () => {
      switch (true) {
        case /jpg/.test(loadedType):
        case /jpeg/.test(loadedType):
        case /gif/.test(loadedType):
        case /bmp/.test(loadedType):
        case /png/.test(loadedType):
        case /webp/.test(loadedType):
          return Image;
        case /video/.test(loadedType):
        case /audio/.test(loadedType):
          return Media;
        case /note/.test(loadedFileName):
        case /md/.test(loadedFileName):
        case /md/.test(loadedType):
        case /markdown/.test(loadedType):
          return Note;
        case /plain/.test(loadedType):
          return PlainText;
        case /pdf/.test(loadedType):
          return Pdf;
        case /csv/.test(loadedType):
          return Csv;
        case /doc/.test(loadedType):
        case /docx/.test(loadedType):
          return Doc;
        // return Md;
        case /json/.test(loadedType):
          return Json;
        default:
          if (!isError) {
            props.loadErrorRef.current = true;
            setIsError(true);
          }
          return null;
      }
    };

    const iFramePrint = data => {
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.write(`<html><head><style>p{font-size: 16pt; white-space: pre-wrap}</style></head><body><p>${data}</p></body></html>`);
      doc.close();
      iframe.focus();
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    };

    const print = async () => {
      if (loadedType.startsWith("image")) {
        // working on Safari, Chrome, Firefox
        printJS(loadedSrc, "image");
      } else if (loadedType.includes("pdf")) {
        // working on Safari, Chrome, Firefox
        printJS(loadedSrc, "pdf");
      } else if (loadedType.includes("markdown")) {
        // working on Safari, Chrome, Firefox
        iFramePrint(loadedSrc);
      } else if (loadedType.includes("text/plain")) {
        // Working on Chrome, Firefox
        const response = await fetch(loadedSrc);
        const text = await response.text();
        iFramePrint(text);
      } else if (/doc/.test(loadedType) || /docx/.test(loadedType)) {
        // working on Safari, Chrome, Firefox
        const jsonFile = new XMLHttpRequest();
        jsonFile.open("GET", loadedSrc, true);
        jsonFile.send();
        jsonFile.responseType = "arraybuffer";
        jsonFile.onreadystatechange = () => {
          if (jsonFile.readyState === 4 && jsonFile.status === 200) {
            mammoth
              .convertToHtml({ arrayBuffer: jsonFile.response }, { includeDefaultStyleMap: true })
              .then(result => {
                const myWindow = window.open();
                myWindow.document.write(result.value);
                myWindow.print();
                myWindow.close();
              })
              .catch(a => {
                console.log("Could not open doc", a);
              });
          }
        };
      } else return;
    };

    const download = async () => {
      props.onDownload();
    };

    const renderLoader = () => (
      <Box
        ref={loaderRef}
        height={props.height}
        width={isMobile ? `${window.innerWidth - 32}px` : props.width}
        sx={{
          bgcolor: "background.card",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <ImageIcon sx={{ fontSize: "100px", margin: `${props.height / 3}px` }} color="secondary"></ImageIcon>
      </Box>
    );

    if (!loadedSrc || !loadedType) {
      return renderLoader();
    }
    const renderFile = () => {
      if (isLoading) {
        return renderLoader();
      } else if (isError || props.size > props.previewLimitSize) {
        return (
          <FileViewerError
            src={loadedSrc}
            name={loadedFileName}
            overSized={props.size > props.previewLimitSize}
            previewLimitSize={props.previewLimitSize}
            mode={props.mode}
            width={props.width}
            height={props.height}
            onDownload={props.onDownload}
          ></FileViewerError>
        );
      } else {
        return (
          Viewer && (
            <Viewer
              width={props.width}
              height={props.height}
              src={loadedSrc}
              className={props.className}
              fixedHeight={props.mode === "group"}
              type={props.type}
            />
          )
        );
      }
    };
    const Viewer = getDriver();

    return <Box>{renderFile()}</Box>;
  }
);

export default File;
