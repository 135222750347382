import {
  DarkModeGalleryIcon,
  DownloadIcon,
  ImageIcon,
  LightModeGalleryIcon,
  LinkIcon,
  PrinterIcon,
  ViewTransactionIcon
} from "@akord/addon-icons";
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { FileInput, Handle } from "./types";
import { FileType } from "../FileViewer/File/types";

interface Props {
  darkMode: Boolean;
  isDrawerOpened: boolean;
  publicShareEnabled: boolean;
  activeFile: ActiveFileProps;
  activeFileGroup: Array<FileInput>;
  handleDrawerOpen: () => void;
  onThemeSwitch: () => void;
  handleDownload: () => void;
  handleModal: () => void;
  onPrint: () => void;
  onDarkMode: React.Dispatch<React.SetStateAction<Boolean>>;
  transactionBaseUrl: string;
}

interface ActiveFileProps {
  fileName: string;
  hash: string;
  type: FileType;
  isLoading: boolean;
  print: () => void;
  download: () => void;
}

export const MobileMenuDrawer = (props: Props) => {
  const {
    darkMode,
    isDrawerOpened,
    activeFile,
    activeFileGroup,
    publicShareEnabled,
    handleDrawerOpen,
    transactionBaseUrl,
    onThemeSwitch,
    handleDownload,
    handleModal,
    onDarkMode,
    onPrint
  } = props;

  const handleMenuClick = action => {
    switch (action) {
      case "download":
        handleDownload();
        handleDrawerOpen();
        break;
      case "share":
        handleModal();
        handleDrawerOpen();
        break;
      case "transaction":
        window.open(`${transactionBaseUrl}/${activeFileGroup[0].txId}`, "_blank");
        handleDrawerOpen();
        break;
      case "print":
        onPrint();
        handleDrawerOpen();
        break;
      case "colorMode":
        onDarkMode(!darkMode);
        onThemeSwitch && onThemeSwitch();
        handleDrawerOpen();
        break;

      default:
        handleDrawerOpen();
    }
  };

  return (
    <Drawer anchor="bottom" elevation={16} open={isDrawerOpened} sx={{ zIndex: 1300 }} onClose={handleDrawerOpen}>
      <Box mx={5}>
        <List component="nav" aria-label="active files" style={{ listStyleType: "none" }}>
          <Box my={3}>
            <ListItem
              disableGutters
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "0px"
              }}
            >
              <ListItemIcon>
                <ImageIcon
                  color="action"
                  sx={{
                    boxSizing: "content-box",
                    padding: "5px",
                    background: publicShareEnabled ? "background.stack" : "background.active",
                    color: publicShareEnabled ? "info.main" : "primary.main",
                    fontSize: "20px"
                  }}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography noWrap variant="body2" color="text.primary">
                  {activeFile?.fileName || (activeFileGroup && activeFileGroup[0]?.name)}
                </Typography>
              </ListItemText>
            </ListItem>
          </Box>
          <Divider />
          <Box my={4}>
            <ListItemButton
              sx={{
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px"
              }}
              onClick={() => handleMenuClick("download")}
            >
              <ListItemIcon
                sx={{
                  color: grey[500],
                  boxSizing: "content-box",
                  padding: "5px"
                }}
              >
                <DownloadIcon sx={{ fontSize: "20px" }}></DownloadIcon>
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" color="text.primary">
                  Download
                </Typography>
              </ListItemText>
            </ListItemButton>
          </Box>
          {publicShareEnabled && (
            <Box my={4}>
              <ListItemButton
                sx={{
                  paddingLeft: "0px",
                  paddingTop: "0px",
                  paddingBottom: "0px"
                }}
                onClick={() => handleMenuClick("share")}
              >
                <ListItemIcon
                  sx={{
                    color: grey[500],
                    boxSizing: "content-box",
                    padding: "5px"
                  }}
                >
                  <LinkIcon sx={{ fontSize: "20px" }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2" color="text.primary">
                    Share file
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </Box>
          )}
          {activeFileGroup && (
            <Box my={4}>
              <ListItemButton
                sx={{
                  paddingLeft: "0px",
                  paddingTop: "0px",
                  paddingBottom: "0px"
                }}
                disabled={!activeFileGroup[0].txId}
                onClick={() => handleMenuClick("transaction")}
              >
                <ListItemIcon
                  sx={{
                    color: grey[500],
                    boxSizing: "content-box",
                    padding: "5px"
                  }}
                >
                  <ViewTransactionIcon sx={{ fontSize: "20px" }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2" color="text.primary">
                    View transaction
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </Box>
          )}
          <Box my={4}>
            <ListItemButton
              sx={{
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px"
              }}
              onClick={() => handleMenuClick("print")}
            >
              <ListItemIcon
                sx={{
                  color: grey[500],
                  boxSizing: "content-box",
                  padding: "5px"
                }}
              >
                <PrinterIcon sx={{ fontSize: "20px" }}></PrinterIcon>
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" color="text.primary">
                  Print
                </Typography>
              </ListItemText>
            </ListItemButton>
          </Box>
          <Box my={4}>
            <ListItemButton
              sx={{
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px"
              }}
              onClick={() => handleMenuClick("colorMode")}
            >
              <ListItemIcon
                sx={{
                  color: grey[500],
                  boxSizing: "content-box",
                  padding: "5px"
                }}
              >
                {darkMode ? <LightModeGalleryIcon sx={{ fontSize: "20px" }} /> : <DarkModeGalleryIcon sx={{ fontSize: "20px" }} />}
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" color="text.primary">
                  {darkMode ? "Light mode" : "Dark mode"}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </Box>
        </List>
      </Box>
    </Drawer>
  );
};
