import { ChevronLeftIcon, ChevronRightIcon } from "@akord/addon-icons";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { grey } from "../../../theme/colors";
import { Props } from "../types";
import "./styles.css";

const Pdf = (props: Props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(null);
  const [navButtonsHideTimeout, setNavButtonsHideTimeout] = useState(null);
  const navButtons = useRef<HTMLDivElement>();
  const maxWidth = window.innerWidth * 0.8;

  const defaultWorker = () => {
    if (!pdfjs.GlobalWorkerOptions.workerPort) {
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
      pdfjs.GlobalWorkerOptions.workerPort = new pdfjs.PDFWorker("default-worker");
    }
    return pdfjs.GlobalWorkerOptions.workerPort;
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
    handleMouseMove();
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
    handleMouseMove();
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const handleMouseEnter = () => {
    if (navButtons.current) {
      navButtons.current.style.opacity = "90%";
      if (navButtonsHideTimeout) {
        clearTimeout(navButtonsHideTimeout);
      }
    }
  };

  const handleMouseLeave = () => {
    setNavButtonsHideTimeout(
      setTimeout(() => {
        if (navButtons.current) {
          navButtons.current.style.opacity = "0";
        }
      }, 2000)
    );
  };

  const handleMouseMove = () => {
    handleMouseEnter();
    handleMouseLeave();
  };
  const isWindowVertical = window.innerHeight > window.innerWidth;

  return (
    <Box
      style={{
        // minWidth: `${props.width}px`,
        maxHeight: props.height ? `${props.height}px` : "100%",
        display: "contents"
      }}
      onMouseMove={() => handleMouseMove()}
    >
      <Document file={props.src} options={{ worker: defaultWorker() }} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          loading={
            <div
              style={{
                fontSize: "0.8625rem",
                height: props.height,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: ["Inter", "sans-serif"].join(",")
              }}
            >
              Loading page...
            </div>
          }
          onRenderSuccess={(page) => {
            if (page.width > props.width) {
              setWidth(page.width > maxWidth ? maxWidth : page.width);
            }
            // handles diff page orientation inside a pdf
            if (page.height > props.height) {
              // page aspect ratio
              const n = page.width / page.height;
              if (props.height * n !== width) setWidth(props.height * n);
            }
          }}
          pageNumber={pageNumber}
          className={[props.className, "pdf-canvas-container"]}
          height={props.height}
          width={isWindowVertical ? props.width : width}
        />
      </Document>

      {numPages && numPages > 1 && (
        <Box
          sx={{
            position: "absolute",
            display: "inline-block",
            bottom: "0px",
            left: "50%",
            backgroundColor: grey[400],
            opacity: "90%",
            borderRadius: "5px",
            transform: "translate(-50%, 0)",
            transition: "opacity ease-out 0.5s"
          }}
          className="swiper-no-swiping"
          ref={(e) => (navButtons.current = e as HTMLDivElement)}
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
        >
          <IconButton
            disabled={pageNumber <= 1}
            onClick={previousPage}
            sx={{
              padding: "7px",
              marginLeft: "5px",
              color: grey[300],
              ":hover": {
                color: "white"
              }
            }}
          >
            <ChevronLeftIcon sx={{ fontSize: "24px", transform: "none !important" }} />
          </IconButton>
          <Typography variant="caption" color="white">
            {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </Typography>
          <IconButton
            disabled={pageNumber >= numPages}
            onClick={nextPage}
            sx={{
              padding: "7px",
              marginRight: "5px",
              color: grey[300],
              ":hover": {
                color: "white"
              }
            }}
          >
            <ChevronRightIcon sx={{ fontSize: "24px", transform: "none !important" }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default Pdf;
