import React from "react";
import { ArrowLeftIcon, ArrowRightIcon, ArweavePermanentIcon, ChevronLeftIcon, ChevronRightIcon } from "@akord/addon-icons";
import { Box, IconButton } from "@mui/material";
import { grey } from "../../theme/colors";

interface NavigatonArrowProps {
  showPagination: boolean;
  arweaveLogoEnabled: boolean;
}

export const NavigatonArrows = (props: NavigatonArrowProps) => {
  return props.showPagination ? (
    <>
      <Box key="prev" className="swiper-button-prev">
        <ChevronLeftIcon
          sx={{
            paddingRight: 5,
            paddingBottom: 1,
            ":hover": {
              color: "text.primary"
            }
          }}
          color="secondary"
        />
      </Box>
      <Box key="next" className="swiper-button-next">
        <ChevronRightIcon
          sx={{
            paddingLeft: 5,
            paddingBottom: 1,
            ":hover": {
              color: "text.primary"
            }
          }}
          color="secondary"
        />
      </Box>
    </>
  ) : (
    <>
      <Box key="arweaveLogo" position="absolute" display={props.arweaveLogoEnabled ? "flex" : "none"} sx={{ bottom: 4, left: 0 }}>
        <ArweavePermanentIcon color="primary" sx={{ width: "3.7em", height: "auto", fontSize: "28px" }} />
      </Box>

      <Box
        key="prev"
        className="swiper-button-prev"
        style={{
          borderRadius: "50%",
          marginRight: 8,
          marginTop: "8px"
        }}
      >
        <IconButton
          sx={{
            borderRadius: "50%",
            width: 40,
            height: 40,
            bgcolor: "background.tertiary",
            ":hover": {
              bgcolor: "background.tertiary"
            }
          }}
        >
          <ArrowLeftIcon
            sx={{
              color: grey[400],
              ":hover": {
                color: "text.primary"
              }
            }}
          ></ArrowLeftIcon>
        </IconButton>
      </Box>

      <Box
        key="next"
        className="swiper-button-next"
        style={{
          marginLeft: 8,
          marginTop: "8px"
        }}
      >
        <IconButton
          sx={{
            borderRadius: "50%",
            width: 40,
            height: 40,
            bgcolor: "background.tertiary",
            ":hover": {
              bgcolor: "background.tertiary"
            }
          }}
        >
          <ArrowRightIcon
            sx={{
              color: grey[400],
              ":hover": {
                color: "text.primary"
              }
            }}
          ></ArrowRightIcon>
        </IconButton>
      </Box>
    </>
  );
};
