import React from "react";
import { Props } from "../types";

const Media = (props: Props) => {
  const tag = /video/.test(props.type) ? "video" : "audio";
  // quicktime video type needs to be fixed to mp4
  const fileType = /quicktime/.test(props.type) ? "video/mp4" : props.type;

  const maxWidth = window.innerWidth * 0.8
  const width = props.fixedHeight ? props.width : maxWidth

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
        <${tag}
          style="
          display: block;
          width: ${width}px;
          height: ${props.height}px;
          align-items: center;
          justify-content: center;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          "
          controls
          playsinline
          preload="auto"
        >
          <source src="${props.src}" type="${fileType}"/>
        </video>`
      }}
    />
  );
};

export default Media;
