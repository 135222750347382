import { LinkIcon } from "@akord/addon-icons";

export const shareDialoglViewProps = {
  cloud: {
    dialogName: "shareFile",
    Icon: LinkIcon,
    title: "Share public file",
    placeholder: null,
    secondaryText: "The link will share the file from the media viewer where all other files in the vault will also be viewable.",
    nextButton: "Copy link"
  },
  permanent: {
    dialogName: "shareFile",
    Icon: LinkIcon,
    title: "Share public file",
    placeholder: null,
    secondaryText: "This will display the file in Akord's media viewer. The arweave.net link is found in  ‘File info’.",
    nextButton: "Copy link"
  }
};

export const shareDialogViewOnSubmit = (inputValue: string) => navigator.clipboard.writeText(inputValue);
