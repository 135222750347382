import React from "react";

export interface FormSubmit {
  children: React.ReactNode;
  onSubmit: () => void;
}

const FormSubmit = (props: FormSubmit) => {
  const { children, onSubmit } = props;
  //to enable Enter submit
  return (
    <form
      action="/"
      method="POST"
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {children}
    </form>
  );
};

export default FormSubmit;
