import { Box, CircularProgress, Theme, Typography, useMediaQuery } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";

export interface LabelSpinnerProps {
  label: string;
  mobileLabel?: string;
  showLabel?: boolean;
  children?: React.ReactNode;
  component?: React.ElementType;
  ref?: React.Ref<any>;
  sx?: SxProps<Theme>;
}

const LabelSpinner = (props: LabelSpinnerProps) => {
  const isMobile = useMediaQuery("(max-width:668px)");

  return (
    <Box
      sx={{
        ...props.sx,
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        height: "fit-content",
        border: `1px solid`,
        borderColor: "info.main",
        borderRadius: "4px",
        padding: 1,
        paddingLeft: 2,
        paddingRight: 2
      }}
    >
      {props.showLabel !== false && (
        <Box mr={1}>
          <Typography
            variant="body2"
            sx={{
              color: "info.light"
            }}
            className="small"
          >
            {isMobile ? props.mobileLabel : props.label}
          </Typography>
        </Box>
      )}
      <CircularProgress
        size="1rem"
        color="secondary"
        sx={{
          position: "inherit"
        }}
      />
    </Box>
  );
};

export default LabelSpinner;
