import { Box } from "@mui/material";
import mammoth from "mammoth";
import React, { useEffect, useState } from "react";
import { Props } from "../types";

const Doc = (props: Props) => {
  const [data, setData] = useState<string>();

  useEffect(() => {
    (window as any).global = window;
    // @ts-ignore
    window.Buffer = window.Buffer || require("buffer").Buffer;

    const jsonFile = new XMLHttpRequest();
    jsonFile.open("GET", props.src, true);
    jsonFile.send();
    jsonFile.responseType = "arraybuffer";
    jsonFile.onreadystatechange = () => {
      if (jsonFile.readyState === 4 && jsonFile.status === 200) {
        mammoth
          .convertToHtml({ arrayBuffer: jsonFile.response }, { includeDefaultStyleMap: true })
          .then(result => {
            setData(result.value);
          })
          .catch(a => {
            console.log("Could not open doc", a);
          });
      }
    };
  }, []);

  if (!data) {
    return null;
  }
  return (
    <span className="swiper-no-swiping" style={{ textAlign: "left" }}>
      <Box
        sx={{
          height: `${props.height - 20}px`,
          width: `${props.width}px`,
          overflow: "scroll",
          bgcolor: "white",
          color: "black"
        }}
        dangerouslySetInnerHTML={{ __html: `<div style="padding: 20px">${data}</div>` }}
      ></Box>
    </span>
  );
};

export default Doc;
