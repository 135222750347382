import { FolderOutlineIcon } from "@akord/addon-icons";
import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { grey } from "../../../theme/colors";

export type Props = {
  width: number | "auto";
  height: number;
  mode: "single" | "group";
  onClose: () => void;
};

const FileViewerNoFiles = (props: Props) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const renderBody = () => (
    <Box>
      <Box marginBottom="8px">
        <FolderOutlineIcon color="disabled" />
      </Box>
      <Typography variant="h2" style={{ marginBottom: "8px" }}>
        No files to view
      </Typography>
      <Typography variant="body2" style={{ marginBottom: "8px" }}>
        There are no files here, only folders.
      </Typography>
      <Button
        style={{ minWidth: "auto" }}
        variant="contained"
        color="primary"
        disableElevation
        type="button"
        onClick={() => props.onClose()}
      >
        Exit media viewer
      </Button>
    </Box>
  );

  if (props.mode === "single") {
    return (
      <Box
        overflow={"auto"}
        width={props.width}
        height={props.height}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          borderRadius="8px"
          px={7}
          pt={3}
          pb={6}
          sx={{ bgcolor: isDarkMode ? grey[800] : grey[100] }}
        >
          {renderBody()}
        </Box>
      </Box>
    );
  }
  return (
    <Box
      margin="auto"
      textAlign="center"
      position="relative"
      width={props.width}
      height={props.height}
      sx={{ bgcolor: isDarkMode ? grey[800] : grey[100] }}
    >
      <Box
        margin={0}
        position="absolute"
        top="50%"
        style={{
          transform: "translateY(-50%)"
        }}
        boxSizing="border-box"
        px={7}
        pt={3}
        pb={6}
      >
        {renderBody()}
      </Box>
    </Box>
  );
};

export default FileViewerNoFiles;
