const grey = {
  900: "#222126",
  800: "#312F37",
  700: "#403F46",
  600: "#53515C",
  500: "#7C7986",
  400: "#9D9BA6",
  300: "#D1CFD8",
  200: "#E8E6EF",
  100: "#F7F6FA"
};
const orange = {
  900: "#38251E",
  800: "#621B04",
  700: "#DB400F",
  600: "#F05828",
  500: "#F26D43",
  400: "#FB8A65",
  300: "#FEAA90",
  200: "#FFC8B8",
  100: "#FFECE5"
};
const blue = {
  900: "#1D2772",
  800: "#2937A3",
  700: "#3145DD",
  600: "#5164F6",
  500: "#7D8CFF",
  400: "#A3AEFF",
  300: "#C7CDFF",
  200: "#D6DBFF",
  100: "#F0F1FF"
};

export { grey, orange, blue };
