import '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    myCustomColor?: Palette['primary'];
  }
  interface PaletteOptions {
    myCustomColor?: PaletteOptions['primary'];
  }

  interface TypeBackground {
    secondary?: string,
    tertiary?: string,
    switch?: string,
    dark?: string,
    archived?: string,
    active?: string,
    card?: string,
    cardBorder?: string,
    stack?: string,
    folder?: string,
    spinnerText?: string,
    spinnerBox?: string,
    icon?: string,
  }

  interface TypeText {
    tertiary?: string
  }

  interface PaletteColor {
    iconInvert?: string;
    darkest?: string
  }

  interface SimplePaletteColorOptions {
    secondary?: string,
    darkest?: string,
    iconInvert?: string,
    disabled?: string
  }

  interface Components<Theme = unknown> {
    MuiTimelineConnector?: {
      styleOverrides?: {};
    },
    MuiTimelineDot?: {
      styleOverrides?: {};
    }
    MuiTimeline?: {
      styleOverrides?: {};
    }
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsSizeOverrides {
    xs: true
  }
}